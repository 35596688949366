require("jquery");
require("bootstrap");
require("jquery-mask-plugin/dist/jquery.mask.min");
import AOS from "aos";
import Swal from "sweetalert2/dist/sweetalert2.js";
//import Swiper from 'swiper';
require("jquery.animate-number");

window._ = require("lodash");

try {
  window.Popper = require("@popperjs/core").default;
  window.$ = window.jQuery = require("jquery");
} catch (e) {
  console.log(e);
}

jQuery(function () {
  toggleMenusOnScroll();
  backToTop();
  initArrowNavigation(".animationPosition .arrowsAnimation button", "header");
  mainHeight();

  $(window).scroll(function () {
    toggleMenusOnScroll();
    backToTop();
    mainHeight();
  });

  $(window).on("resize", function () {
    toggleMenusOnScroll();
    backToTop();
    initArrowNavigation(".animationPosition .arrowsAnimation button", "header");
    mainHeight();
  });

  $(document).ready(function () {
    toggleMenusOnScroll();
    backToTop();
    //swiperAos();
    initArrowNavigation(".animationPosition .arrowsAnimation button", "header");
    mainHeight();
    AOS.init({
      duration: 1000, // Duração da animação
      once: true, // Se a animação deve ocorrer apenas uma vez
    });
  });

  $(window).on("load", function () {
    toggleMenusOnScroll();
    backToTop();
    mainHeight();
    initArrowNavigation(".animationPosition .arrowsAnimation button", "header");
  });

  function toggleMenusOnScroll() {
    const firstMenu = $(".header-main");
    const secondMenu = $(".header-scroll");
    const mobileMenu = $(".header-mobile");
    const topBar = $(".top-bar");
    const lineProgressBar = $(".line-progress");
    const header = $("header");

    // Captura a altura do primeiro menu
    const firstMenuHeight = firstMenu.outerHeight();

    const updateMenuVisibility = () => {
      const scrolled = $(window).scrollTop() > firstMenuHeight;

      // Alterna visibilidade dos menus
      firstMenu
        .toggleClass("d-none", scrolled)
        .toggleClass("d-lg-flex", !scrolled);
      secondMenu
        .toggleClass("d-none", !scrolled)
        .toggleClass("d-lg-flex", scrolled);
      lineProgressBar
        .toggleClass("d-none", !scrolled)
        .toggleClass("d-flex", scrolled);
      secondMenu.toggleClass("scrolled", scrolled);
      mobileMenu.toggleClass("scrolled", scrolled);
      topBar.toggleClass("d-block", !scrolled).toggleClass("d-none", scrolled);

      // Alterna IDs
      if (scrolled) {
        firstMenu.removeAttr("id");
        secondMenu.attr("id", "main-menu");
      } else {
        firstMenu.attr("id", "main-menu");
        secondMenu.removeAttr("id");
      }

      // Alterna classes no header
      header.toggleClass("bg-grey", scrolled);
    };

    // Adiciona o evento scroll
    $(window).on("scroll", updateMenuVisibility);

    // Inicialmente oculta o segundo menu e configura o estado atual
    secondMenu.addClass("d-none");
    updateMenuVisibility();
  }

  function backToTop() {
    const backToTopButton = $(".backToTop");
    const lineProgressBar = $(".line-progress");

    // Cache de valores para evitar chamadas repetidas
    const documentElement = $(document.documentElement);
    const bodyElement = $("body");

    // Atualiza visibilidade e estilo do botão e barra de progresso
    const setBackToTopVisibility = () => {
      const scrollTop = $(window).scrollTop();
      const heightHeader = $("header").outerHeight();
      const documentHeight = $(document).outerHeight();
      const windowHeight = $(window).height();
      const progress = (scrollTop / (documentHeight - windowHeight)) * 100;

      const value = getComputedStyle(document.documentElement).getPropertyValue(
        "--bs-primary"
      );

      // Define posição horizontal do botão
      const rightPosition =
        scrollTop > heightHeader ?
        $(window).width() > 960 ?
        "1%" :
        "5%" :
        "-100%";

      // Atualiza estilo do botão
      backToTopButton.css({
        right: rightPosition,
        background: `conic-gradient(${value} ${progress}%, transparent ${progress}%)`,
      });

      // Atualiza estilo da barra de progresso
      lineProgressBar.css({
        background: `linear-gradient(90deg, ${value} ${progress}%, transparent ${progress}%)`,
      });
    };

    // Rola para o topo com animação
    const handleBackToTopClick = () => {
      $("html, body").stop(true, true).animate({
          scrollTop: 0,
        },
        300
      ); // Tempo ajustado para 300ms
    };

    // Configuração inicial
    setBackToTopVisibility();

    // Eventos
    backToTopButton.on("click", handleBackToTopClick);
    $(window).on("resize scroll", setBackToTopVisibility);
  }

  function mainHeight() {
    const header = $("header");
    const footer = $("footer");
    const main = $("main");
    const mainMenu = $("#main-menu");

    const heightMainMenu = mainMenu.length ? mainMenu.outerHeight() : 0;
    const heightFooter = footer.length ? footer.outerHeight() : 0;
    const heightWindow = $(window).outerHeight();

    if (mainMenu.hasClass("fixed-top") && !mainMenu.hasClass("d-none")) {
      main.css("margin-top", heightMainMenu);
    }

    header.css("height", heightMainMenu);
    main.css("min-height", heightWindow - heightMainMenu - heightFooter);
  }

  // function swiperAos() {
  //   try {
  //     const swiper = new Swiper('.mySwiper', {
  //       loop: true,
  //       pagination: {
  //         el: '.swiper-pagination',
  //         clickable: true,
  //       },
  //       navigation: {
  //         nextEl: '.swiper-button-next',
  //         prevEl: '.swiper-button-prev',
  //       },
  //       on: {
  //         slideChangeTransitionEnd: function () {
  //           if (typeof AOS !== 'undefined' && typeof AOS.refreshHard === 'function') {
  //             AOS.refreshHard();
  //           }
  //         },
  //       },
  //     });
  //   } catch (error) {
  //     console.error('Erro ao inicializar o Swiper:', error);
  //   }
  // }

  function initArrowNavigation(buttonSelector, headerSelector) {
    $(buttonSelector).on("click", function () {
      const currentSection = $(this).closest("section");
      let nextSection = currentSection.next("section");

      // Verifica o próximo "section" válido
      while (nextSection.length === 0 && currentSection.next().length > 0) {
        currentSection = currentSection.next();
        nextSection = currentSection.next("section");
      }

      // Realiza o scroll até a próxima seção
      if (nextSection.length > 0) {
        const dataScroll = nextSection.attr("id");
        const targetOffset = $(`#${dataScroll}`).offset().top;
        const headerHeight = $(headerSelector).outerHeight() || 0;

        $("html, body").animate({
            scrollTop: targetOffset - headerHeight,
          },
          600 // Tempo da animação em ms
        );
      }
    });
  }

  $(document).ready(function () {

    grecaptcha.ready(function () {
      grecaptcha.execute('6Lfw4ZkqAAAAAETDtiBbal82b7rywvFBL7Wtaa1y', {
        action: 'submit'
      }).then(function (token) {
        document.getElementById('g-recaptcha-response').value = token;
      });
    });

    var swiper = new Swiper(".galeria", {
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      speed: 800,
      loop: true,
    });

    var swiper = new Swiper(".depoimentos", {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      speed: 800,
      loop: true,
    });

    //Telefone
    let phoneFields = $(
      '[type="tel"], [id="tel"], [name="f_telefone"], [name="f_celular"]'
    );

    let phoneMaskBehavior = function (val) {
      if (val !== undefined && val !== null) {
        return val.replace(/\D/g, "").length === 11 ?
          "(00) 00000-0000" :
          "(00) 0000-00009";
      }
    };

    let phoneOptions = {
      onKeyPress: function (val, e, field, options) {
        field.mask(phoneMaskBehavior.apply({}, arguments), options);
      },
    };
    phoneFields.mask(phoneMaskBehavior, phoneOptions);
    phoneFields.attr("inputmode", "numeric");

    $(".counter").each(function () {
      var targetNumber = parseInt($(this).text().replace(/\D/g, ""), 10) || 0;

      $(this).animateNumber({
        number: targetNumber,
        easing: 'swing',
        duration: 5000
      });
    });

    const $container = $(".border-gradient-animed");

    // Adiciona a classe "active" para iniciar a animação
    setTimeout(function () {
      $container.addClass("active");
    }, 100); // Pequeno atraso antes de começar

    // Captura a posição inicial do scroll
    var scrollPosition = $(window).scrollTop();

    // Adiciona a classe 'scrolled' se a posição inicial não for 0
    if (scrollPosition !== 0) {
      $(".menu").addClass("scrolled");
    }

    // Adiciona a classe 'scrolled' ao rolar
    $(window).scroll(function () {
      var scrollPosition = $(this).scrollTop();

      if (scrollPosition > 0) {
        $(".navbar").addClass("scrolled");
        $(".navbar").addClass("fixed-top");
        if ($("#wpadminbar").hasClass("mobile")) {
          $("nav").css("margin-top", 0);
          $(".offcanvas").css("top", 0);
        }
      } else {
        $(".navbar").removeClass("scrolled");
        if (
          !$("#wpadminbar").hasClass("mobile") &&
          !$(".navbar").hasClass("fx")
        ) {
          $(".navbar").removeClass("fixed-top");
        }
      }
    });
  });

  function masks() {
    //Phone
    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, "").length === 11 ?
          "(00) 00000-0000" :
          "(00) 0000-00009";
      },
      spOptions = {
        onKeyPress: function (val, e, field, options) {
          field.mask(SPMaskBehavior.apply({}, arguments), options);
        },
      };

    $('.mask-phone , [type="tel"], [id="tel"]').mask(SPMaskBehavior, spOptions);

    //CEP
    let cep = $('.mask-cep, [name="cep"], [id="cep"]');
    cep.mask("00000-000", {
      reverse: true,
    });
    cep.attr("inputmode", "numeric");

    //CPF
    let cpf = $('.mask-cpf, [name="cpf"], [id="cpf"]');
    cpf.mask("000.000.000-00", {
      reverse: true,
    });
    cpf.attr("inputmode", "numeric");

    //CNPJ
    let cnpj = $('.mask-cnpj, [name="cnpj"], [id="cnpj"]');
    cnpj.mask("00.000.000/0000-00", {
      reverse: true,
    });
    cnpj.attr("inputmode", "numeric");

    //CPF ou CNPJ
    let cpfCnpj = $('.mask-cpf-cnpj, [name="cpf-cnpj"], [id="cpf-cnpj"]');
    let options = {
      onKeyPress: function (cpf, ev, el, op) {
        let masks = ["000.000.000-000", "00.000.000/0000-00"];
        cpfCnpj.mask(cpf.length > 14 ? masks[1] : masks[0], op);
      },
    };
    cpfCnpj.length > 11 ?
      cpfCnpj.mask("00.000.000/0000-00", options) :
      cpfCnpj.mask("000.000.000-00#", options);
    cpfCnpj.attr("inputmode", "numeric");

    //Credit number
    let creditCard = $(
      '.mask-credit-card, [name="credit-card"], [id="credit-card"]'
    );
    creditCard.mask("0000 0000 0000 0000", {
      reverse: true,
    });
    creditCard.attr("inputmode", "numeric");

    //CVV
    let cvv = $('.mask-cvv, [name="cvv"], [id="cvv"]');
    cvv.mask("000", {
      reverse: true,
    });
    cvv.attr("inputmode", "numeric");

    //Date
    let date = $('.mask-date, [name="date"], [id="date"], [type="date"]');
    date.mask("00/00/0000", {
      reverse: true,
    });
    date.attr("inputmode", "numeric");

    //Month and Year
    let monthYear = $('.mask-monthYear, [name="monthYear"], [id="monthYear"]');
    monthYear.mask("00/0000", {
      reverse: true,
    });
    monthYear.attr("inputmode", "numeric");
  }
});